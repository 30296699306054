import { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import logo from "../../../assets/logo/logo.png";
import eraser from "../../../assets/eraser.png";
import Pen from "../../../assets/Pen.png";
import pencil from "../../../assets/pencil.png";

const AutomatedExam = ({ questions, setStudentAnswers, file }) => {
  console.log(questions)
  const [selections, setSelections] = useState({
    pencil: {},
    bluePen: {},
  });
  const [finalAnswers, setFinalAnswers] = useState({});

  const [currentTool, setCurrentTool] = useState("none");
  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    certificate: "",
    studentName: "",
    motherName: "",
    grade: "",
    examNumber: "",
    subject: "",
    branch: "",
    date: "",
  });

  useEffect(() => {
    setShow(true);
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  const handleTableSelection = (e, column, tableId) => {
    const selectedValue = e.target.value;
    const key = `${tableId}-${column}`;

    setSelections((prev) => {
      const updatedSelections = { ...prev };

      if (currentTool === "eraser") {
        if (updatedSelections.bluePen[key] === selectedValue) {
          return prev;
        }

        if (updatedSelections.pencil[key] === selectedValue) {
          delete updatedSelections.pencil[key];
        }
        if (updatedSelections.bluePen[key] === selectedValue) {
          delete updatedSelections.bluePen[key];
        }
      }

      if (currentTool === "pencil") {
        updatedSelections.pencil = {
          ...updatedSelections.pencil,
          [key]: selectedValue,
        };
      }

      if (currentTool === "bluePen") {
        updatedSelections.bluePen = {
          ...updatedSelections.bluePen,
          [key]: selectedValue,
        };
      }
      handleSubmit();
      return updatedSelections;
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  function transformQuestions(questions, transformedObject) {
    const letters = ['A', 'B', 'C', 'D']; // Map letters to options index
    const result = [];

    Object.keys(transformedObject).forEach(key => {
      const questionIndex = parseInt(key, 10) - 1; // Get the question index (key - 1)
      const letter = transformedObject[key]; // Get the letter (A, B, C, D)

      if (questions[questionIndex]) {
        const question = questions[questionIndex]; // Find the corresponding question
        const optionIndex = letters.indexOf(letter); // Get the index of the letter

        if (optionIndex !== -1 && question.options[optionIndex]) {
          const option = question.options[optionIndex]; // Find the matching option
          result.push({
            aqq_id: question.id, // Question ID
            option_id: option.id // Option ID
          });
        }
      }
    });

    return result;
  }
  const handleSubmit = () => {


    console.log("Selected Options:", selections);
    console.log("Selected Options FINAL :", selections.bluePen);

    // Transform the object to use numeric keys
    const transformedObject = Object.keys(selections.bluePen)
      .filter(key => key.startsWith("optionsTable2") || key.startsWith("optionsTable")) // Filter relevant keys
      .reduce((result, key) => {
        const match = key.match(/\d+$/); // Extract numeric part at the end
        if (match) {
          result[match[0]] = selections.bluePen[key]; // Assign the value to the new numeric key
        }
        return result;
      }, {});
    const finalResult = transformQuestions(questions, transformedObject);

    console.log(finalResult)
    setStudentAnswers(finalResult)
    console.log("Transformed Object:", transformedObject);
    console.log("Form Data:", formData);
  };

  const getToolButtonClass = (tool) => {
    if (currentTool === tool) {
      switch (tool) {
        case "eraser":
          return "btn-danger";
        case "pencil":
          return "btn-warning";
        case "bluePen":
          return "btn-primary";
        default:
          return "";
      }
    }
    return "btn-secondary";
  };

  const renderTable = (tableId, startRow, endRow, type = "options") => (
    <div
      className="table-container"
      style={{ overflowX: "auto", whiteSpace: "nowrap" }}
    >
      <table className="table" style={{ minWidth: "100%" }}>
        <thead>
          <tr>
            {type === "options" ? (
              <>
                <th></th>
                <th style={{ color: "#b85757", fontWeight: "bold" }}>A</th>
                <th style={{ color: "#b85757", fontWeight: "bold" }}>B</th>
                <th style={{ color: "#b85757", fontWeight: "bold" }}>C</th>
                <th style={{ color: "#b85757", fontWeight: "bold" }}>D</th>
              </>
            ) : (
              <th
                colSpan={11}
                style={{
                  fontSize: "23px",
                  backgroundColor: "#b85757",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                I.D. NUMBER
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: endRow - startRow + 1 }, (_, rowIndex) => {
            const rowNumber = startRow + rowIndex;
            return (
              <tr key={`${tableId}-${rowNumber}`}>
                <td>{rowNumber}</td>

                {type === "options"
                  ? ["A", "B", "C", "D"].map((option) => (
                    <td key={option}>
                      <label className="radio-container">
                        <input
                          type="radio"
                          name={`${tableId}-row${rowNumber}`}
                          value={option}
                          onClick={(e) =>
                            handleTableSelection(
                              e,
                              `row${rowNumber}`,
                              tableId
                            )
                          }
                          disabled={currentTool === "none"}
                          checked={
                            selections.pencil[
                            `${tableId}-row${rowNumber}`
                            ] === option ||
                            selections.bluePen[
                            `${tableId}-row${rowNumber}`
                            ] === option
                          }
                        />
                        <span
                          className="radio-label"
                          style={{
                            backgroundColor:
                              selections.bluePen[
                                `${tableId}-row${rowNumber}`
                              ] === option
                                ? "blue"
                                : selections.pencil[
                                  `${tableId}-row${rowNumber}`
                                ] === option
                                  ? "gray"
                                  : "white",
                          }}
                        >
                          {option}
                        </span>
                      </label>
                    </td>
                  ))
                  : Array.from({ length: 10 }, (_, colIndex) => {
                    const cellValue = colIndex;
                    return (
                      <td key={`col-${rowNumber}-${colIndex}`}>
                        <label className="radio-container">
                          <input
                            type="radio"
                            name={`${tableId}-col${colIndex}`}
                            value={rowNumber}
                            onClick={(e) =>
                              handleTableSelection(
                                e,
                                `col${colIndex}`,
                                tableId
                              )
                            }
                            disabled={currentTool === "none"}
                            checked={
                              selections.pencil[
                              `${tableId}-col${colIndex}`
                              ] === `${rowNumber}` ||
                              selections.bluePen[
                              `${tableId}-col${colIndex}`
                              ] === `${rowNumber}`
                            }
                          />
                          <span
                            className="radio-label"
                            style={{
                              backgroundColor:
                                selections.bluePen[
                                  `${tableId}-col${colIndex}`
                                ] === `${rowNumber}`
                                  ? "blue"
                                  : selections.pencil[
                                    `${tableId}-col${colIndex}`
                                  ] === `${rowNumber}`
                                    ? "gray"
                                    : "white",
                            }}
                          >
                            {rowNumber}
                          </span>
                        </label>
                      </td>
                    );
                  })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  return (
    <>

      <div className="col-md-12 mt-3">
        <label className="form-label">الأسئلة</label>
        <embed
          src={`${process.env.REACT_APP_STORAGE_URL}${file}`}
          type="application/pdf"
          width="100%"
          height="400px"
          style={{ border: "1px solid #ddd" }}
        />
      </div>
      <Container fluid className="p-3">
        <Row>


          <Col>

            <Row className="mt-5">
              <Col xs={12} md={6} className="mb-3 mb-md-0">
                <div className="border border-danger p-1 small-table">
                  {renderTable("optionsTable", 1, 50, "options")}
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="border border-danger p-1 small-table">
                  {renderTable("optionsTable2", 51, 100, "options")}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* Submit Button */}
      {/* <div className="text-center mt-5">
        <button className="btn btn-success" onClick={handleSubmit}>
          Submit
        </button>
      </div> */}

      {/* Tool Buttons */}
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <button
          onClick={() => setCurrentTool("eraser")}
          className={`btn ${getToolButtonClass("eraser")}`}
          title="ممحاة"
          style={{
            padding: "10px",
            minWidth: "50px",
            minHeight: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={eraser}
            alt="Eraser"
            style={{
              width: "30px",
              height: "30px",
            }}
          />
        </button>

        <button
          onClick={() => setCurrentTool("pencil")}
          className={`btn ${getToolButtonClass("pencil")}`}
          title="قلم رصاص"
          style={{
            padding: "10px",
            minWidth: "50px",
            minHeight: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={pencil}
            alt="Pencil"
            style={{
              width: "30px",
              height: "30px",
            }}
          />
        </button>

        <button
          onClick={() => setCurrentTool("bluePen")}
          className={`btn ${getToolButtonClass("bluePen")}`}
          title="قلم أزرق"
          style={{
            padding: "10px",
            minWidth: "50px",
            minHeight: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Pen}
            alt="Blue Pen"
            style={{
              width: "30px",
              height: "30px",
            }}
          />
        </button>
      </div>

      {/* Modal */}
      {show && (
        <div
          className="modal fade show d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close position-absolute top-0 end-0 me-2 mt-2"
                onClick={handleClose}
                aria-label="Close"
              ></button>
              <div className="modal-header position-relative">
                <h5 className="modal-title">تنبيهات الامتحان</h5>
              </div>

              <div className="modal-body">
                <ul>
                  <li>يرجى التأكد من مراجعة الأسئلة بعناية قبل تسليم الإجابة.</li>
                  <li>يمكنك استخدام القلم الرصاص لتحديد الإجابات مؤقتاً .</li>
                  <li>يمكنك فقط مسح الإجابات المحددة بالقلم الرصاص من قبلك باستخدام الممحاة .</li>
                  <li>لا تستخدم القلم الأزرق إلا إذا كنت متأكد من إجابتك لأنك لن تتمكن من التراجع عنها . </li>
                  <li>لن يتم احتساب أي سؤال غير الأسئلة التي تم تحديدها بالقلم الأزرق .</li>
                  <li>للبدء بتحديد إجاباتك أولاً انقر على القلم المناسب " أزرق أو رصاص " ومن ثم قم بتحديد الجواب .</li>
                </ul>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  فهمت
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AutomatedExam;
